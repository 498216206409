<template>
  <div
    :class="classList.container"
    :ref="ref"
    v-click-outside="resetSliderData"
  >
    <div class="container-main pt-0">
      <div :class="classList.bar">
        <ul class="pull-left">
          <li v-for="category in gameCategory" :key="category">
            <a
              @click="handleGameClick(category)"
              :class="
                gameCategorySlider === category &&
                classList.selectedCategoryLabel
              "
            >
              {{ t(category) }}
            </a>
          </li>
        </ul>
        <span>{{ time }}</span>
      </div>
    </div>
    <section v-for="category in gameCategory" :key="category">
      <HorizontalList
        v-if="gameCategorySlider === category"
        :itemsPerScreen="itemsPerScreen"
        :getList="gameCategoryGetList[category]"
      />
    </section>
  </div>
</template>
<script>
import {
  Digit,
  Module,
  EMPTY_ARRAY,
  GAME_PLAY_NAVIGATION,
  SET_NEEDS_TIMER_RESET,
  GamePlayCategoryEnum,
} from '@/constants'
import { isNil, Timer, commitMutation, requestInterval } from '@/helpers'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

const CSN_GAMEPLAY_NAVIGATION = 'csn-gameplay-navigation'
const CSN_GAMEPLAY_NAVIGATION_WITH_HIGH_SLIDER = `${CSN_GAMEPLAY_NAVIGATION}-with-high-slider`
const CSN_GAMEPLAY_NAVIGATION_WITH_SLIDER = `${CSN_GAMEPLAY_NAVIGATION}-with-slider`
const CSN_GAMEPLAY_NAVIGATION_FOOTER_BAR = `${CSN_GAMEPLAY_NAVIGATION}-footer-bar`
const CSN_GAMEPLAY_NAVIGATION_SELECTED_CATEGORY = `${CSN_GAMEPLAY_NAVIGATION}-selected-category`
const IFAME = 'IFRAME'

const GameCategoryGetList = {
  [GamePlayCategoryEnum.TOP]: GameApi.getTopGameList,
  [GamePlayCategoryEnum.RECENT]: GameApi.getRecentGameList,
  [GamePlayCategoryEnum.FAVOURITE]: GameApi.getFavouriteGameList,
  [GamePlayCategoryEnum.RECOMMENDED]: GameApi.getRecommendedGameList,
}

export default {
  name: GAME_PLAY_NAVIGATION,
  mixins: [cardsPerScreenCalculationMixin],
  data() {
    return {
      gameCategorySlider: null,
      monitorIframeClick: null,
      timer: null,
      timerInterval: null,
      time: null,
    }
  },
  components: {
    HorizontalList: () => import('@/components/HorizontalList'),
  },
  computed: {
    classList() {
      return {
        container: [
          CSN_GAMEPLAY_NAVIGATION,
          ...(isNil(this.gameCategorySlider)
            ? EMPTY_ARRAY
            : [
                this.gameCategorySlider === this.gameCategory.TOP
                  ? CSN_GAMEPLAY_NAVIGATION_WITH_HIGH_SLIDER
                  : CSN_GAMEPLAY_NAVIGATION_WITH_SLIDER,
              ]),
        ],
        bar: CSN_GAMEPLAY_NAVIGATION_FOOTER_BAR,
        selectedCategoryLabel: CSN_GAMEPLAY_NAVIGATION_SELECTED_CATEGORY,
      }
    },
    gameCategory: () => GamePlayCategoryEnum,
    gameCategoryGetList: () => GameCategoryGetList,
    t() {
      return this.$createComponentTranslator(GAME_PLAY_NAVIGATION)
    },
  },
  watch: {
    gameCategorySlider(value) {
      if (isNil(value)) {
        return
      }
      const self = this

      this.monitorIframeClick = requestInterval(() => {
        const element = document.activeElement

        if (element && element.tagName == IFAME) {
          self.resetSliderData()
        }
      }, Digit.TWO_HUNDRED)
    },
    NEEDS_TIMER_RESET(value) {
      if (!value) {
        return
      }

      this.resetTime()
      commitMutation([Module.MAIN, SET_NEEDS_TIMER_RESET], false)
    },
  },
  methods: {
    handleGameClick(category) {
      this.gameCategorySlider = category
    },
    resetSliderData() {
      this.gameCategorySlider = null

      if (isNil(this.monitorIframeClick)) {
        return
      }

      this.monitorIframeClick.clear()
      this.monitorIframeClick = null
    },
    startTimer() {
      this.timer = new Timer()
      this.timer.start()
      this.timerInterval = requestInterval(this.updateTime, Digit.TWO_HUNDRED)
    },
    dropTimer() {
      this.time && (this.time = null)
      this.timer && this.timer.stop()
      this.timerInterval &&
        this.timerInterval.clear() &&
        (this.timerInterval = null)
    },
    resetTime() {
      this.timerInterval &&
        this.timerInterval.clear() &&
        (this.timerInterval = null)

      this.timer && this.timer.reset()
      this.timerInterval = requestInterval(this.updateTime, Digit.TWO_HUNDRED)
    },
    updateTime() {
      const timeInSeconds = Math.round(
        this.timer.getTime() / Digit.ONE_THOUSAND,
      )
      this.time = new Date(timeInSeconds * Digit.ONE_THOUSAND)
        .toISOString()
        .substr(Digit.FOURTEEN, Digit.FIVE)
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    this.dropTimer()
  },
}
</script>
